import { useNavigation } from '@remix-run/react'
import imageCompression, { Options } from 'browser-image-compression'
import { parseISO, format, addHours, subMonths } from 'date-fns'
import { he } from 'date-fns/locale'
import i18next, { t } from 'i18next'
import { useEffect } from 'react'
import { PoolLogReport, ToxicSubstance } from '~/generated/api'

export function formatDateWithOffset(date: Date) {
    const pad = (num) => (num < 10 ? '0' : '') + num

    const year = date.getFullYear()
    const month = pad(date.getMonth() + 1)
    const day = pad(date.getDate())
    const hours = pad(date.getHours())
    const minutes = pad(date.getMinutes())
    const seconds = pad(date.getSeconds())
    const milliseconds = date.getMilliseconds()

    const offset = -date.getTimezoneOffset()
    const sign = offset >= 0 ? '+' : '-'
    const offsetHours = pad(Math.floor(Math.abs(offset) / 60))
    const offsetMinutes = pad(Math.abs(offset) % 60)

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${sign}${offsetHours}:${offsetMinutes}`
}

export function convertDateToISOWithTimezone(dateString) {
    // Parse the input date string to a Date object
    const date = parseISO(dateString)

    // Adjust the date to the desired timezone offset (e.g., +03:00)
    const offsetHours = 3 // +03:00
    const adjustedDate = addHours(date, offsetHours)

    // Format the adjusted date to include time and timezone offset
    const formattedDate = format(adjustedDate, 'yyyy-MM-dd HH:mm:ssxxx')

    return formattedDate
}
export function extractTimeWithOffset(timestamp, offsetHours = 3) {
    // Parse the ISO timestamp to a Date object
    const date = parseISO(timestamp)

    // Add the time zone offset to the Date object
    const adjustedDate = addHours(date, offsetHours)

    // Format the adjusted date to H:mm
    return format(adjustedDate, 'HH:mm')
}

export function groupResultsByDate(results) {
    const groupedResults = {}

    results?.forEach((result) => {
        if (!result?.created_at) {
            result.sent = false
        }

        const date = result.pool_log_date.split('T')[0].split('+')[0]
        if (!groupedResults[date]) {
            groupedResults[date] = []
        }
        groupedResults[date].push(result)
    })

    return groupedResults
}

export function groupTestResultsByDate(results) {
    const groupedResults = {}

    results?.forEach((result) => {
        const date = result.pool_log_date.split('T')[0]

        if (!groupedResults[date]) {
            groupedResults[date] = {
                tests: 0,
                comments: 0,
            }
        }
        groupedResults[date].tests++
        result.comments && groupedResults[date].comments++
    })

    return groupedResults
}

export function groupResultsByPool(results: PoolLogReport[]) {
    const groupedResults = {}

    results?.forEach((result) => {
        if (!groupedResults[result.pool]) {
            groupedResults[result.pool] = {
                poolName: result.pool_name,
                results: [],
            }
        }
        groupedResults[result.pool].results.push(result)
    })

    return groupedResults
}

export function getPastMonths(currentMonth: string) {
    const months = Array.from({ length: Number(currentMonth) }).map((_, i) => {
        const today = new Date()
        const date = subMonths(today, i)
        return {
            timeToShow: format(date, 'MMMM yyyy', { locale: he }),
            date: format(date, 'MM-yyyy'),
        }
    })
    return months
}

export function localizePhone(phone: string | null | undefined) {
    if (!phone) {
        return
    }
    if (i18next.language === 'he') {
        return '0' + phone.slice(4)
    } else {
        return phone
    }
}

export function deLocalizePhone(phone: string | null | undefined) {
    if (!phone) {
        return
    }
    if (i18next.language === 'he') {
        return '+972' + phone.slice(1)
    } else {
        return '+' + phone
    }
}

export function rawTextToShow(text: string) {
    text = text?.replace(/[-_]/g, ' ')
    const words = text?.split(' ')
    const capitalizedWords = words?.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1)
    )
    return capitalizedWords?.join(' ')
}

export function localPic(image: string) {
    if (process.env.NODE_ENV === 'development' && image) {
        return image.replace('8080', '8000')
    } else {
        return image
    }
}

export function base64ToFile(base64String, fileName) {
    // Decode the base64 string to binary data
    const byteString = atob(base64String.split(',')[1])

    // Create an array buffer
    const arrayBuffer = new ArrayBuffer(byteString.length)
    const uintArray = new Uint8Array(arrayBuffer)

    // Fill the array with binary data
    for (let i = 0; i < byteString.length; i++) {
        uintArray[i] = byteString.charCodeAt(i)
    }

    // Create a Blob from the array buffer
    const blob = new Blob([arrayBuffer], { type: 'image/png' })

    // Convert the Blob to a File
    const file = new File([blob], fileName, { type: 'image/png' })

    return file
}

export function formatDate(dateDelat: number) {
    const date = new Date()

    date.setDate(date.getDate() + dateDelat)

    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')

    return `${year}-${month}-${day}`
}

export function addItemToLocalStorageArray<T>(key: string, newItem: T): void {
    // Retrieve the existing array from localStorage
    const existingArray: T[] = localStorage.getItem(key)
        ? JSON.parse(localStorage.getItem(key) as string)
        : []

    // Add the new item to the array
    existingArray.push(newItem)

    // Save the updated array back to localStorage
    localStorage.setItem(key, JSON.stringify(existingArray))
}
export function convertDateToHebrew(dateString: string): string {
    const lang = i18next.language
    const isHeb = lang === 'he'
    const monthsMap: { [key: string]: string } = {
        '01': isHeb ? 'ינואר' : 'January',
        '02': isHeb ? 'פברואר' : 'Februay',
        '03': isHeb ? 'מרץ' : 'March',
        '04': isHeb ? 'אפריל' : 'April',
        '05': isHeb ? 'מאי' : 'May',
        '06': isHeb ? 'יוני' : 'June',
        '07': isHeb ? 'יולי' : 'July',
        '08': isHeb ? 'אוגוסט' : 'August',
        '09': isHeb ? 'ספטמבר' : 'September',
        '10': isHeb ? 'אוקטובר' : 'October',
        '11': isHeb ? 'נובמבר' : 'November',
        '12': isHeb ? 'דצמבר' : 'December',
    }

    const [month, year] = dateString.split('-')
    const hebrewMonth = monthsMap[month]
    const res = isHeb
        ? `${hebrewMonth}, ${parseInt(year, 10)}`
        : format(dateString, 'MMMM YY')
    return res
}
export function formatImageUrl(url: string) {
    if (!url) {
        return
    }
    if (import.meta.env.MODE == 'development') {
        return url.replace('8080', '8000')
    }

    return url
}

export function useScrollUp() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
}

export function creatHazardSubtitle(
    t,
    hazardStaticData: ToxicSubstance | undefined
) {
    const res =
        t`מספר או"ם ` +
        `${hazardStaticData?.un_number}, ` +
        t`קבוצת סיכון ` +
        `${hazardStaticData?.hazard_group}, ` +
        t`קוד חירום` +
        `${hazardStaticData?.emergency_code}`

    return res
}

export function blobToBase64(blob: Blob) {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}

export function fileToBase64(file: File) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader()

        // Event handler executed when the reading is successful
        reader.onloadend = () => {
            resolve(reader.result)
        }

        // Event handler executed if an error occurs during the reading
        reader.onerror = reject

        // Read the file as a data URL (Base64 encoded string)
        reader.readAsDataURL(file)
    })
}

export async function handleImageUpload(attachment: File) {
    const options: Options = {
        maxSizeMB: 0.1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
    }
    try {
        const compressedFile = await imageCompression(attachment, options)
        //Convert Blob to File
        const file = new File([compressedFile], 'image.jpg', {
            type: 'image/jpeg',
        })
        return file // write your own logic
    } catch (error) {
        console.log(error)
    }
}

export const monthsMap: { [key: string]: string } = {
    '1': 'ינואר',
    '2': 'פברואר',
    '3': 'מרץ',
    '4': 'אפריל',
    '5': 'מאי',
    '6': 'יוני',
    '7': 'יולי',
    '8': 'אוגוסט',
    '9': 'ספטמבר',
    '10': 'אוקטובר',
    '11': 'נובמבר',
    '12': 'דצמבר',
}

export function calculateDifference(date1: Date, date2: Date) {
    // Calculate the difference in milliseconds
    const millisecondsDifference = Math.abs(date2.getTime() - date1.getTime())

    // Convert milliseconds to minutes
    let difference = millisecondsDifference / (1000 * 60)
    let unit = t`minutes`
    if (difference > 60) {
        unit = t`hours`
        difference = difference / 60
    } else if (difference > 24) {
        unit = t`days`
        difference = difference / 24
    } else if (difference > 30) {
        unit = t`months`
        difference = difference / 30
    }
    return difference.toFixed(0) + ' ' + unit
}

export function useIsSubmitting() {
    const navigation = useNavigation()
    const isSubmitting =
        navigation.state === 'submitting' || navigation.state === 'loading'

    return isSubmitting
}

export const pushDataLayerGTM = (
    category,
    action,
    label = null,
    nonInteraction = false,
    value = null
) => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
        event: 'event',
        eventProps: {
            category: category,
            action: action,
            label: label,
            value: value,
            nonInteraction: nonInteraction,
        },
    })
}

export function authStaging() {
    if (
        location.host.includes('staging') &&
        !localStorage.getItem('staging-access')
    ) {
        const password = prompt('what is the secret password???')
        if (password !== 'Benefit!23') {
            return (window.location.href =
                'https://www.benefit-for-pools.co.il')
        } else {
            localStorage.setItem('staging-access', 'true')
        }
    }
}
